import {InputHTMLAttributes, useCallback, useEffect, useState} from "react";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";
import useModal from "./useModal";
import { KYCDocumentInterface } from "../../../domain/Folder/KYCControl";

interface IPreviewModal {
    id: string,
    kyc: KYCDocumentInterface,
    hide?: () => void;
    className?: string;
}


const PreviewKYCModal = ({ id, kyc, hide, className } :IPreviewModal) => {
    const {t} = useTranslation()
    const [isPreviewDocument, togglePreviewDocument] = useModal();
    const [modalTitle, setModalTitle] = useState<string>('');
    const [modalContent, setModalContent] = useState<InputHTMLAttributes<string>>();

    const handleHide = useCallback(() => {
        togglePreviewDocument()
        if (hide) {
            hide()
        }
    }, [])

    const moralPerson = (kyc.person?.siret || kyc.person?.legalForm) ? (
        <>
            <div className="col-md-6">
                <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Statut juridique:</span> : <span className={`u-text-size-s`}>{kyc.person?.legalForm ?? 'N/A'}</span>
            </div>
            <div className="col-md-6">
                <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Raison sociale:</span> : <span className={`u-text-size-s`}>{kyc.person?.legalname ?? 'N/A'}</span>
            </div>
            <div className="col-md-6">
                <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Siret:</span> : <span className={`u-text-size-s`}>{kyc.person?.siret ?? 'N/A'}</span>
            </div>
        </>
    ) : (
        <></>
    );

    return (
        <Modal title={t('modal.kyc.title')}
            description={modalTitle}
            customClassName={className ?? `modal`}
            hide={handleHide}
            loading={false}>
            <div className={`flex-container padding`}>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Nom</span> : <span className={`u-text-size-s`}>{kyc.person?.lastname ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Prénom</span> : <span className={`u-text-size-s`}>{kyc.person?.firstname ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Nom d'usage</span> : <span className={`u-text-size-s`}>{kyc.person?.maidenname ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Nationalité</span> : <span className={`u-text-size-s`}>{kyc.person?.nationality ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Date de naissance</span> : <span className={`u-text-size-s`}>{kyc.person?.birthDate ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Lieu de naissance</span> : <span className={`u-text-size-s`}>{kyc.person?.birthPlace ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Rue </span> : <span className={`u-text-size-s`}>{kyc.person?.address?.street ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Code Postal</span> : <span className={`u-text-size-s`}>{kyc.person?.address?.zipcode ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Ville</span> : <span className={`u-text-size-s`}>{kyc.person?.address?.city ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Pays</span> : <span className={`u-text-size-s`}>{kyc.person?.address?.country ?? 'N/A'}</span>
                </div>
                
                {moralPerson}
            </div>
        </Modal>
    )
}

export default PreviewKYCModal;
